






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { required, numeric } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import {
  Condition,
  StepFiveInterface,
  StepTwoInterface,
} from '@/store/modules/room.module'
import { namespace } from 'vuex-class'
const RoomStore = namespace('RoomStore')

// const validations = {
//   price: { required, numeric },
// }

@Component({
  name: 'StepFive',
  components: { FormGroup, Field, BaseButton, BaseIcon },
  // validations,
})
export default class StepThree extends Vue {
  price: number | null = null

  @RoomStore.State('conditions')
  conditions!: Condition[]

  @Watch('conditions')
  onConditionsChanged(val: Condition[]): void {
    this.update()
  }

  @Prop({ required: true })
  value!: StepFiveInterface

  public update(): void {
    this.$emit('input', {
      conditions: this.conditions,
    })
  }
}
